import React from "react";
import "./App.css";
import Zoom from "./Zoom";

function App() {
  return (
    <div className="app">
      <Zoom id="test" />
    </div>
  );
}

export default App;
